
.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &&::-webkit-scrollbar {
        display: none;
    }
}

.ant-select-selection-placeholder {
    color: white !important;
}
.ant-picker-input > input::placeholder {
    color: #F0F0F0 !important;
}


/*
    Scroller
*/
:root {
    --color-text: navy;
    --color-bg: papayawhip;
    --color-bg-accent: #ecdcc0;
    --size: clamp(10rem, 1rem + 40vmin, 30rem);
    --gap: calc(var(--size) / 14);
    --duration: 60s;
    --scroll-start: 0;
    --scroll-end: calc(-100% - var(--gap));
}
@media (prefers-color-scheme: dark) {
    :root {
        --color-text: papayawhip;
        --color-bg: navy;
        --color-bg-accent: #2626a0;
    }
}
    
* {
    box-sizing: border-box;
}

.marquee {
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);
    mask-image: linear-gradient(
        var(--mask-direction, to right),
        hsl(0 0% 0% / 0),
        hsl(0 0% 0% / 1) 30%,
        hsl(0 0% 0% / 1) 70%,
        hsl(0 0% 0% / 0)
    );
}
    
.marquee__group {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: var(--gap);
    min-width: 100%;
    animation: scroll-x var(--duration) linear infinite;
}
    
@media (prefers-reduced-motion: reduce) {
    .marquee__group {
        animation-play-state: paused;
    }
}

.marquee--vertical {
    --mask-direction: to bottom;
}

.marquee--vertical,
.marquee--vertical .marquee__group {
    flex-direction: column;
}
    
.marquee--vertical .marquee__group {
    animation-name: scroll-y;
}

.marquee--reverse .marquee__group {
    animation-direction: reverse;
    animation-delay: -3s;
}

@keyframes scroll-x {
    from {
        transform: translateX(var(--scroll-start));
    }
    to {
        transform: translateX(var(--scroll-end));
    }
}

@keyframes scroll-y {
    from {
        transform: translateY(var(--scroll-start));
    }
    to {
        transform: translateY(var(--scroll-end));
    }
}

/* Parent wrapper */
.wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    margin: auto;
    width: 100%;
    max-width: 100vw;
}

.wrapper--vertical {
    flex-direction: row;
    height: 80vh;
}

/* Toggle direction button */
.toggle {
    --size: 3rem;
    position: relative;
    position: fixed;
    top: 1rem;
    left: 1rem;
    width: var(--size);
    height: var(--size);
    font: inherit;
    text-align: center;
    cursor: pointer;
    outline: none;
    border: none;
    border-radius: 50%;
    color: inherit;
    background-color: var(--color-bg-accent);
    z-index: 1;
}

.toggle:focus-visible {
    box-shadow: 0 0 0 2px var(--color-text);
}

.toggle span {
    position: absolute;
    display: inline-block;
    top: 50%;
    left: calc(100% + 0.4em);
    width: fit-content;
    white-space: nowrap;
    transform: translateY(-50%);
    animation: fade 400ms 4s ease-out forwards;
    user-select: none;
}

.toggle svg {
    --size: 1.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--size);
    height: var(--size);
    fill: currentcolor;
    transform: translate(-50%, -50%);
    transition: transform 300ms cubic-bezier(0.25, 1, 0.5, 1);
}
.toggle--vertical svg {
    transform: translate(-50%, -50%) rotate(-90deg);
}

@keyframes fade {
    to {
        opacity: 0;
        visibility: hidden;
    }
}

/*
    Animations
*/

.reveal{
    transform: translateY(150px);
    opacity: 0;
    transition: 1.5s all ease;
}
.reveal.active{
    transform: translateX(0);
    opacity: 1;
}
.reveal-top{
    transform: translateY(-150px);
    opacity: 0;
    transition: 1.5s all ease;
}
.reveal-top.active {
    transform: translateX(0);
    opacity: 1;
}
.reveal-right{
    transform: translateX(150px);
    opacity: 0;
    transition: 3s all ease;
}
.reveal-right.active{
    transform: translateX(0);
    opacity: 1;
}
.reveal-left{
    transform: translateX(-150px);
    opacity: 0;
    transition: 1.5s all ease;
}
.reveal-left.active{
    transform: translateX(0);
    opacity: 1;
}
  
.loading-bubble {
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.dot {
    width: 5px;
    height: 5px;
    margin: 0 2px;
    background-color: white; /* Change color to suit your theme */
    border-radius: 50%;
    animation: load-bounce 1.5s infinite;
}

.dot:nth-child(2) {
    animation-delay: 0.3s;
}

.dot:nth-child(3) {
    animation-delay: 0.6s;
}

@keyframes load-bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-8px);
    }
}
  

@media only screen and (max-width: 1000px) {
    .no-mobile {
        display: none !important;
    }

}
  